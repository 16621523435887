export const projects = [
  {
    id: 0,
    name: "KingGPT",
    demo: "https://aiproject-gavinstahl.vercel.app/",
    img: "https://i.gyazo.com/d717d357d1f34d1ed87807e6082c7ca5.png",
    alt: "KingGPT preview",
    repo: "https://github.com/stahlgazer/aiproject",
    description: `AI Software as a Service platform that utilizes the newest artifical intelligence models for users to get questions answered, code problems solved, image, video, and music generation.`,
  },
  {
    id: 1,
    name: "Our Digital Cookbook",
    demo: "https://cookbookapp.vercel.app/",
    img: "https://i.gyazo.com/1e2a13fb0f4f8be9bea26cdbbf7afa6e.png",
    alt: "ODC company preview",
    repo: "https://github.com/stahlgazer/cookbookapp",
    description: `A digital cookbook and community where users can browse recipes other users made, create and manage their own recipes in the dashboard, and search for any recipes by name or category.`,
  },
  {
    id: 2,
    name: "Bumblelight",
    demo: "https://devsite-neon.vercel.app/",
    img: "https://i.gyazo.com/98b1654522251521c7da0e85ce3a6a2b.png",
    alt: "Bumblelight company preview",
    repo: "https://github.com/stahlgazer/devsite",
    description: `We beelieve that every business deserves a strong and captivating online presence. Our mission is to bring your vision to life in the digital realm. We craft digital solutions that resonate with your audience and drive meaningful results.`,
  },
  {
    id: 3,
    name: "The Movie Ark",
    demo: "https://moviefinder-nine.vercel.app/",
    img: "https://i.gyazo.com/67dc2dc311b32e7e16590157468478c9.png",
    alt: "The Movie Ark demo preview",
    repo: "https://github.com/stahlgazer/moviefinder",
    description: `Web app to show the most popular new movies, as well as
    the ability to search for any movie released for their ratings, details,
    trailers and more.`,
  },
  {
    id: 4,
    name: "Memory Match",
    demo: "https://memorymatch.vercel.app/",
    img: "https://i.gyazo.com/471238877fd8752fcf0d2f3b2874afe0.png",
    alt: "memory match preview",
    repo: "https://github.com/stahlgazer/memorymatch",
    description: `Memory Match, a challenging and fun memory game that will put your skills to the test! Can you rise through the levels and become the ultimate Memory Master?`,
  },
  {
    id: 5,
    name: "Game of Life",
    demo: "https://game-of-life-smoky-zeta.vercel.app/",
    img: "https://i.gyazo.com/753ddf95779b053560ad3fe989a2bf75.png",
    alt: "game of life preview",
    repo: "https://github.com/stahlgazer/game-of-life",
    description: `Not your ordinary computer game. This is a game based on Conway's Game of Life, which demonstrates cellular automaton. Invented by John Conway.`,
  },
  {
    id: 6,
    name: "Recipe API+Database",
    demo: "https://cookbookdb.onrender.com/",
    img: "https://i.gyazo.com/cecf3c36d0b2850a10d117e33e3324a1.png",
    alt: "Github documentation preview for recipe database",
    repo: "https://github.com/stahlgazer/dbfromscratch",
    description: `Custom API and Database created for Our Digital Cookbook web application. Features full CRUD with express.js, node.js, knex.js, and postgres`,
  },
  {
    id: 7,
    name: "Suggester API+Database",
    demo: "https://spotify-backend-kj2h.onrender.com/",
    img: "https://i.gyazo.com/0285186db25c275218566b9115e43d87.png",
    alt: "api documentation preview",
    repo: "https://github.com/bw-ft-spotify-song-suggester-1/back-end",
    description: `API and database created with postgres, express, knex. Complete with middleware, user authentication, and testing. For a group project called Spotify Song Suggester. See repo for examples.`,
  },
  {
    id: 8,
    name: "Julie the Foodie",
    demo: "https://juliethefoodie.vercel.app/",
    img: "https://i.gyazo.com/f0d966db8ed6c7d8306baa56e40b9fbf.png",
    alt: "banner logo for julie the foodie",
    repo: "https://github.com/stahlgazer/juliethefoodie",
    description: `Marketing website for Julie the Foodie. I created a straight forward single page application for a client based upon their needs.`,
  },
  // {
  //   id: 9,
  //   name: "Spotify Song Suggester",
  //   demo: "https://frontend-tan.vercel.app/",
  //   img: "https://i.gyazo.com/288efe7aa6b9c33b4735492c311763e0.png",
  //   alt: "song suggester login page preview",
  //   repo: "https://github.com/Spotify-Song-Suggester-3/frontend",
  //   description: `Web application for users to find songs through the Spotify API and a recommendation algorithm built by data science members in a group project.`,
  // },
  // {
  //   id: 10,
  //   name: "BioBid Labs",
  //   demo: "https://admindash-rho.vercel.app/",
  //   img: "https://i.gyazo.com/c026635eac773f415149292e93788b95.png",
  //   alt: "biobid page preview",
  //   repo: "https://github.com/BloomTech-Labs/bio-bid-fe",
  //   description: `Project for a stakeholder with a group of developers and a project manager. Worked on Both front-end and back-end of this project, the login/logout and user functionality/permissions. Unfortunately the project isn't live, so here is a UI of the admin dashboard.`,
  // },

  // {
  //   id: 11,
  //   name: "Med Cabinet",
  //   demo: "https://medcabinet.vercel.app/",
  //   img: "https://i.gyazo.com/001adb67a3c5e1e08b7b557883e07b02.png",
  //   alt: "medcabinet preview",
  //   repo: "https://github.com/stahlgazer/medcabinet",
  //   description: `Group project web app for users to find individualized recommendations for medical marijuana based on the user's needs and preferences. Users can alter their preferences in their dashboard for new recommendations.`,
  // },
];
