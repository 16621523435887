import React from "react";
import mui from "../images/material-ui.png";
import redux from "../images/redux.png";
import graphql from "../images/graphql.png";
import git from "../images/git.png";
import postgresql from "../images/postgresql.png";
import jest from "../images/jest.png";
import nextjs from "../images/nextjs.png";
import prisma from "../images/prisma.png";
import typescript from "../images/typescript.png";
import mysql from "../images/mysql.png";
import npm from "../images/npm.png";
import tailwind from "../images/tailwind.png";
import zustand from "../images/zustand.png";

export default function Skills() {
  return (
    <section id="Skills" className="nav-fix">
      <div>
        <h2>Skills / Languages</h2>
      </div>
      <div className="mobile-icons-container">
        <div
          data-aos="fade-down-right"
          data-aos-duration="1000"
          data-aos-delay="300"
          className="icons-container"
          data-aos-mirror="true"
        >
          <div className="icondiv">
            <i className="fab fa-html5"></i>
            <p>Html5</p>
          </div>
          <div className="icondiv">
            <i className="fab fa-css3-alt"></i>
            <p>CSS3</p>
          </div>
          <div className="icondiv">
            <i className="fab fa-js-square"></i>
            <p>JavaScript</p>
          </div>
          <div className="icondiv">
            <i className="fab fa-react"></i>
            <p>React.js</p>
          </div>
          <div className="icondiv">
            <i className="fab fa-sass"></i>
            <p>SCSS</p>
          </div>
          <div className="icondiv">
            <i className="fab fa-less"></i>
            <p>LESS</p>
          </div>
          <div className="icondiv">
            <img alt="MaterialUI Logo" src={mui} />
            <p>Material-UI</p>
          </div>
        </div>
        <div
          data-aos="fade-down-left"
          data-aos-delay="300"
          data-aos-duration="1000"
          className="icons-container"
          data-aos-mirror="true"
        >
          <div className="icondiv">
            <img alt="Redux Logo" src={redux} />
            <p>Redux.js</p>
          </div>
          <div className="icondiv">
            <i className="fab fa-node-js"></i>
            <p>Node.js</p>
          </div>
          <div className="icondiv">
            <img alt="jest programming icon" src={jest} />
            <p>Jest</p>
          </div>

          <div className="icondiv">
            <img alt="GraphQL Logo" src={graphql} />
            <p>GraphQL</p>
          </div>
          <div className="icondiv">
            <img alt="PostgreSQL Logo" src={postgresql} />
            <p>PostgreSQL</p>
          </div>
          <div className="icondiv">
            <img alt="Git Logo" src={git} />
            <p>Git</p>
          </div>
          <div className="icondiv">
            <i className="fab fa-python"></i>
            <p>Python</p>
          </div>
        </div>
        <div
          data-aos="fade-up-left"
          data-aos-delay="300"
          data-aos-duration="1000"
          className="icons-container"
          data-aos-mirror="true"
        >
          <div className="icondiv">
            <img alt="Next.js Logo" src={nextjs} />
            <p>Next.js</p>
          </div>
          <div className="icondiv">
            <img alt="Prisma Logo" src={prisma} />
            <p>Prisma</p>
          </div>
          <div className="icondiv">
            <img alt="TypeScript Logo" src={typescript} />
            <p>TypeScript</p>
          </div>
          <div className="icondiv">
            <img alt="MySQL Logo" src={mysql} />
            <p>MySQL</p>
          </div>
          <div className="icondiv">
            <img alt="npm Logo" src={npm} />
            <p>NPM</p>
          </div>
          <div className="icondiv">
            <img alt="Tailwind Logo" src={tailwind} />
            <p>Tailwind CSS</p>
          </div>
          <div className="icondiv">
            <img alt="Zustand Logo" src={zustand} />
            <p>Zustand</p>
          </div>
        </div>
      </div>
    </section>
  );
}
