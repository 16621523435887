import React from "react";
import photo from "../images/gavin.jpg";
import newlogo from "../images/newlogo.png";

export default function About() {
  return (
    <section
      data-aos="fade-down-right"
      data-aos-duration="2500"
      data-aos-mirror="true"
      id="About"
    >
      <img src={photo} alt="me" className="photo" />
      <div className="mydetails" style={{ width: "50%" }}>
        <img
          alt="namelogo"
          src={newlogo}
          style={{ width: "200px", margin: -50 }}
        />
        <h2>Full Stack Software Engineer</h2>
        <p>Redondo Beach, CA</p>
        <a href="mailto:StahlGavin@gmail.com">
          <i className="fa fa-envelope"></i>
        </a>
        <a
          href="https://www.linkedin.com/in/gavin-stahl/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-linkedin"></i>
        </a>
        <a
          rel="external noopener noreferrer"
          href="https://github.com/stahlgazer"
          target="_blank"
        >
          <i className="fab fa-github"></i>
        </a>
        <a
          rel="external noopener noreferrer"
          href="https://twitter.com/stahlgazer"
          target="_blank"
        >
          <i className="fab fa-twitter"></i>
        </a>
        <p>
          Hi, I'm a full-stack software engineer who loves the creativity that comes
          along with building code. I've always been interested in art, but I
          wasn't good at it. Thankfully, programming has morphed art with
          technology and allowed me to express myself in ways I couldn't before.
        </p>
      </div>
    </section>
  );
}
