import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import sky from "../images/sky.png";
import clouds1 from "../images/clouds_1.png";
import clouds2 from "../images/clouds_2.png";
import clouds3 from "../images/clouds_3.png";
import clouds4 from "../images/clouds_4.png";
import rocks1 from "../images/rocks_1.png";
import rocks2 from "../images/rocks_2.png";
import sun from "../images/sun.png";

export default function ParallaxContainer() {
  return (
    <ParallaxBanner style={{ aspectRatio: "2 / 1"}}>
      <ParallaxBannerLayer image={sky} speed={0} />
      <ParallaxBannerLayer image={sun} speed={-20} translateY={[-20, 0]} />
      <ParallaxBannerLayer image={clouds1} speed={-30} />
      <ParallaxBannerLayer image={clouds2} speed={-40} />
      <ParallaxBannerLayer image={clouds3} speed={-30} translateX={[0, 20]} />
      <ParallaxBannerLayer image={clouds4} speed={-30} translateX={[0, 5]} />
      <ParallaxBannerLayer image={rocks1} speed={10} />
      <ParallaxBannerLayer image={rocks2} speed={10} />
    </ParallaxBanner>
  );
}
