import React from "react";
import { projects } from "../data/projects.js";

export default function Projects() {
  return (
    <section id="Projects">
      <div className="nav-fix">
        <h2>Projects</h2>
      </div>
      <div
        className="projects-container"
        data-aos="fade-down-left"
        data-aos-delay="200"
        data-aos-duration="1000"
        data-aos-mirror="true"
      >
        {projects.map((project) => {
          return (
            <div className="single-project" key={project.id}>
              <a target="blank" href={project.demo}>
                <img src={project.img} alt={project.alt} />
              </a>
              <h2>{project.name}</h2>
              <a
                target="blank"
                rel="external noopener noreferrer"
                href={project.repo}
              >
                <i className="fab projfab fa-github"></i>
              </a>
              <p>
                <b>Description: </b>
                {project.description}
              </p>
            </div>
          );
        })}
      </div>
    </section>
  );
}
